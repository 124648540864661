<!--
 * @Author: chenjx
 * @Date: 2023-02-24 15:34:54
 * @LastEditors: chenjx
 * @LastEditTime: 2023-03-05 02:19:32
 * @Description: file content
 * @FilePath: \aitesi\src\components\Footer.vue
-->
<template>
  <div class="footer">
    <div class="footer_top">
      <div class="container">
        <ul>
          <li class="col-md-3 col-xs-6" v-for="(item, index) in bottomdocs" :key="index">
            <p class="p1" v-html="item.title"></p>
            <p class="p2" v-html="item.subtitle"></p>
          </li>
          <!-- <li class="col-md-3 col-xs-6">
            <p class="p1">100000 +</p>
            <p class="p2">服务超10万+企业与个人用户</p>
          </li>
          <li class="col-md-3 col-xs-6">
            <p class="p1">24h</p>
            <p class="p2">7x24 小时服务支持</p>
          </li>
          <li class="col-md-3 col-xs-6">
            <p class="p1">99%</p>
            <p class="p2">灵活计费 节约 99% 发送成本</p>
          </li>
          <li class="col-md-3 col-xs-6">
            <p class="p1">1v1</p>
            <p class="p2">提供 1v1 大客户专项服务</p>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="container">
      <div class="footer_middle">
        <div class="footer_left">
          <div class="logo col-md-8 col-xs-6"><img :src="info.logo2" alt=""></div>
          <div class="text col-md-8 col-xs-6">
            <div class="tel"><img src="../assets/images/footer1.png" alt="">{{ info.tel }}</div>
            <!-- <ul class="list">
              <li>
                <a href=""><img src="../assets/images/footer2.jpg" alt=""></a>
              </li>
              <li>
                <a href=""><img src="../assets/images/footer3.jpg" alt=""></a>
              </li>
              <li>
                <a href=""><img src="../assets/images/footer4.jpg" alt=""></a>
              </li>
              <li>
                <a href=""><img src="../assets/images/footer5.jpg" alt=""></a>
              </li>
              <li>
                <a href=""><img src="../assets/images/footer6.jpg" alt=""></a>
              </li>
              <li>
                <a href=""><img src="../assets/images/footer7.jpg" alt=""></a>
              </li>
            </ul> -->
          </div>
        </div>
        <ul class="footer_center">
          <li class="col-xs-4" v-for="item in footers" :key="item.id">
            <RouterLink :to="`${item.channel_type}?cid${item.id}`">{{ item.title }}</RouterLink>
            <template v-if="item.subs && item.subs.length > 0">
              <RouterLink
                :to="`${child.channel_type}/${encodeURIComponent(child.id)}?id=${encodeURIComponent(item.id)}`"
                v-for="child in item.subs" :key="child.id">
                {{ child.title }}
              </RouterLink>
            </template>
          </li>
        </ul>
        <div class="code">
          <p class="p1"><img :src="info.qrcode_img" alt=""></p>
          <p class="p2" v-html="info.qrcode_text"></p>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <!-- <div class="copyright-text">{{ info.title }}         {{ info.reg_no }}</div> -->
        <div class="copyright-text"><span>{{ info.title }}  ©️ 2023 ALL RIGHTS RESERVED. 保留所有权利 </span> <span></span>        <span v-html="info.reg_no"></span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    footers: {
      default: () => [
        {
          id: "1",
          title: "首页",
          channel_type: "/index",
          image: "",
          subs: [],
        },
        {
          id: "5",
          title: "产品服务",
          channel_type: "/product",
          image: "",
          subs: [
            {
              id: "6",
              title: "卡片视频",
              channel_type: "/product/card",
              image: "",
            },
            {
              id: "7",
              title: "视频短信",
              channel_type: "/product/video",
              image: "",
            },
            {
              id: "8",
              title: "短信",
              channel_type: "/product/message",
              image: "",
            },
          ],
        },
        {
          id: "2",
          title: "文档中心",
          channel_type: "/word",
          image: "",
          subs: [],
        },
        {
          id: "3",
          title: "新闻动态",
          channel_type: "/news",
          image: "",
          subs: [],
        },
        {
          id: "4",
          title: "关于我们",
          channel_type: "/about",
          image: "",
          subs: [],
        },
      ],
      required: true,
      type: Array
    },
    bottomdocs: {
      default: () => [
        {
          "title": "100000+",
          "subtitle": "服务超10万+企业与个人用户",
          "image": ""
        },
        {
          "title": "24h",
          "subtitle": "7x24小时服务支持",
          "image": ""
        },
        {
          "title": "99%",
          "subtitle": "灵活计费节约99%发送成本",
          "image": ""
        },
        {
          "title": "1v1",
          "subtitle": "提供1v1大客户专项服务",
          "image": ""
        }
      ],
      required: true,
      type: Array
    },
    info: {
      default: () => {
        return {
          logo: '',
          qrcode: ''
        }
      },
      required: true,
      type: Object
    }
  },
  data() {
    return {}
  },
}
</script>
<style scoped lang="less">
.footer {
  color: #C7C7C7;
  .footer_top {
    background: #34638C center no-repeat;
    background-size: cover;
    color: #FFF;
    li {
      text-align: center;
      position: relative;
      height: 85px;
      .p1 {
        font-size: 24px;
        margin: 15px 0 0;
      }
      .p2 {
        font-size: 12px;
      }
      &::after {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 10px;
        content: '';
        width: 1px;
        height: 65px;
        background: #FFF;
      }
      &:last-child {
        &::after {
          height: 0;
        }
      }
    }
  }
  .copyright {
    padding: 10px 0;
    border-top: 2px solid #34638C;
    .copyright-text {
      font-size: 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      /deep/ a {
        color: #C7C7C7;
      }
      span:nth-child(1) {
        flex: 1;
      }
    }
  }
}
@media (max-width: 767px) {
  .footer .footer_top ul {
    margin: 0 -15px;
  }
  .footer .footer_top li:nth-child(2)::after {
    height: 0;
  }
  .footer .footer_top li .p1 {
    font-size: 16px;
  }
  .footer .footer_top li .p2 {
    font-size: 10px;
  }
  .footer .copyright .copyright-text span:nth-child(1) {
    flex: inherit;
  }
}
</style>