<!--
 * @Author: chenjx
 * @Date: 2023-02-24 15:11:02
 * @LastEditors: chenjx
 * @LastEditTime: 2023-03-14 15:37:58
 * @Description: file content
 * @FilePath: \aitesi\src\App.vue
-->
<template>
  <div id="app">
    <Header :logo="info.logo" :routerData="headers"></Header>
    <!-- <Aside :info="info"></Aside> -->
    <div class="content-container">
      <keep-alive>
        <RouterView></RouterView>
      </keep-alive>
    </div>
    <Footer :footers="footers" :bottomdocs="bottomdocs" :info="info"></Footer>
  </div>
</template>

<script>

import apis from '@/apis'
import { RouterView } from 'vue-router'
// import Aside from './components/Aside.vue'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Header,
    // Aside,
    Footer,
    RouterView
  },
  data() {
    return {
      info: {},
      headers: [],
      footers: [],
      bottomdocs: [],
    }
  },
  created() {
    this.getBaseInfo()
  },
  methods: {
    /**
     * @description: 获取网页基础信息
     * @return {*}
     */    
    getBaseInfo() {
      this.$http.post(apis.baseInfo).then(res => {
        if (res.data.code === 0) {
          const { info, headers, footers, bottomdocs } = res.data.data
          const menu = {}
          headers.forEach(element => {
              menu[element.channel_type] = element.id
              if (element.channel_type === '/news' || element.channel_type === '/word') {
                element.channel_type = `${element.channel_type}?id=${element.id}`
              }
          });
          this.info = info
          this.headers = headers
          this.footers = footers
          this.bottomdocs = bottomdocs
          document.title = info.title
          sessionStorage.setItem('headers', JSON.stringify(headers))
          sessionStorage.setItem('menu', JSON.stringify(menu))
        }
      })
    }
  }
}
</script>

<style lang="less">
@import './assets/css/reset.css';
@import './assets/css/bootstrap.css';
@import './assets/css/animate.css';
@import './assets/css/swiper.min.css';
@import './assets/css/css.css';
@import './assets/css/mycss.css';
#app::-webkit-scrollbar{
  width: 0;
}
.content-container {
  min-height: calc(100vh - 320px);
}
p {
  line-height: 1.5;
}
.page-banner {
  position: relative;
  h3 {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    left: calc(50% - 50px);
    font-size: 30px;
    color: #000;
  }
}
@media (max-width: 767px) {
  .content-container {
    // padding-top: 65px;
    min-height: calc(100vh - 323px);
  }
  div .header.header2 {
    height: 65px;
  }
  .page-banner h3 {
    font-size: 18px;
    bottom: 10px;
    left: calc(50% - 30px);
  }
}
.router-link-active {
  color: #18466E !important;
}
.footer .router-link-active {
  color: #fff !important;
}
</style>
