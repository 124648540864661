/*
 * @Author: chenjx
 * @Date: 2023-02-24 15:43:15
 * @LastEditors: chenjx
 * @LastEditTime: 2023-03-07 11:27:23
 * @Description: file content
 * @FilePath: \aitesi\src\http\index.js
 */
import axios from 'axios'
import md5 from 'md5'
import { Message } from 'element-ui'

// axios.defaults.baseURL = 'https://ad.xm-best.cn'
axios.defaults.timeout = 10000

/**
 * @description: 请求前拦截处理
 * @return {*}
 */
axios.interceptors.request.use(config => {
  const ctime = Date.now();
  // const key = '39935ad6c142cb0ed8b819df03bbaebc'
  const key = '50c6d55184882e7d6bba84eba73e5e42'
  // eslint-disable-next-line
  // debugger
  config.data = config.data || {}
  config.data.ctime = ctime
  config.data.vsign = md5((key+ctime), 32)
  return config
})

/**
 * @description: 请求结果拦截处理
 * @return {*}
 */
axios.interceptors.response.use(
  (res) => {
    if (res.data.code !== 0) {
      Message.error('网络异常，请稍候重试')
    }
    return res
  },
  (err) => {
    Message.error('网络异常，请稍候重试')
    return Promise.reject(err)
  }
)

export default axios
