import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页'
    },
    redirect: '/index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/index',
    name: 'Index',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/index.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      title: '新闻动态'
    },
    component: () => import('@/views/news.vue')
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    meta: {
      title: '新闻详情'
    },
    component: () => import('@/views/news_detail.vue'),
  },
  {
    path: '/word',
    name: 'Word',
    meta: {
      title: '文档中心'
    },
    component: () => import('@/views/word.vue'),
  },
  {
    path: '/word/:id',
    name: 'WordDetail',
    meta: {
      title: '文档详情'
    },
    component: () => import('@/views/word2.vue')
  },
  {
    path: '/product',
    name: 'Product',
    meta: {
      title: '产品服务'
    },
    component: () => import('@/views/product.vue'),
    children: [
      {
        path: 'card/:id',
        name: 'Card',
        meta: {
          title: '卡片视频'
        },
        component: () => import('@/views/card.vue')
      },
      {
        path: 'video/:id',
        name: 'Video',
        meta: {
          title: '视频短信'
        },
        component: () => import('@/views/video.vue')
      },
      {
        path: 'message/:id',
        name: 'Message',
        meta: {
          title: '短信'
        },
        component: () => import('@/views/meassage.vue')
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于我们'
    },
    component: () => import('@/views/product.vue')
  },
  {
    path: '/about/:id',
    name: 'AboutDetail',
    meta: {
      title: '关于我们'
    },
    component: () => import('@/views/about.vue')
  },
  // {
  //   path: '*',
  //   name: 'Error',
  //   meta: {
  //     title: '首页'
  //   },
  //   component: () => import('@/views/index.vue')
  // },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.name === 'Index') {
      return
    }
    return {
      // 定位到页头
      x: 0,
      y: 0,
    }
  },
})
router.beforeEach((to, from, next) => {
  const headers = JSON.parse(sessionStorage.getItem('headers'))
  const menu = JSON.parse(sessionStorage.getItem('menu'))
  let url = '/'
  if(to.name === 'Product') {
    headers.forEach(item => {
      if (item.channel_type === '/product') {
        url = `/product/message/${encodeURIComponent(item.subs[0].id)}?id=${menu['/product']}`
      }
    })
    next({ path: url })
  } else if (to.name === 'About') {
    headers.forEach(item => {
      if (item.channel_type === '/about') {
        url = `/about/${encodeURIComponent(item.subs[0].id)}?id=${menu['/about']}`
      }
    })
    next({ path: url })
  } else {
    next()
  }
})
export default router